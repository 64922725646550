@use '@angular/material' as mat;
@import 'variables';

@page {
  size: A4;
  margin: 0;
}

* {
  box-sizing: border-box;
}
html {
  // letter-spacing: 0.25px;
}
html,
body {
  height: 100%;
  font: normal #{$baseFontSize}/#{$baseLineHeight} var(--baseFont);
  @media screen and (min-width: $break1) {
    overflow: hidden;
  }
}

body {
  background-color: var(--background);
  margin: 0;
  // margin: 1rem;
  // overflow: hidden;

  color: var(--textColor);
}
a {
  text-decoration: none;
  color: var(--accent);
  font-size: 1rem;
  &:not(.mat-mdc-button):not(.mat-mdc-list-item):not(:disabled):hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  @media print {
    color: var(--primary);
  }
}
body .mat-mdc-button .mat-mdc-button-touch-target {
  height: var(--mdc-text-button-container-height);
}
.cdk-global-scrollblock {
  overflow: hidden;
}

.cdk-overlay-pane .mat-dialog-container {
  padding: 2rem;
}

span.highlight {
  background-color: var(--highlight);
  display: inline !important;
  font-weight: bold;
  border: 1px solid;
  padding: 1px 4px;
  border-radius: 4px;
  color: red;
}

input[bsNumericInput] {
  // text-align: right;
}

body .mat-button-toggle-group-appearance-standard {
  background-color: var(--mat-standard-button-toggle-background-color);
  border-radius: calc(var(--mat-standard-button-toggle-shape) * 1.5);
  padding: 2px;
  .mat-button-toggle-checked {
    border-radius: var(--mat-standard-button-toggle-shape);
  }
  .mat-button-toggle-appearance-standard + .mat-button-toggle-appearance-standard {
    border-left: 0;
  }
}
.mat-button-toggle-appearance-standard {
  .mat-button-toggle-button {
    display: flex;
  }
  .mat-button-toggle-label-content {
    line-height: 20px;
  }
  .mat-pseudo-checkbox {
    display: none;
  }
}
.mat-mdc-form-field-appearance-legacy {
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-mdc-form-field-underline {
    bottom: 0.05em;
  }
}

body .mat-mdc-list.mat-mdc-list-base .mat-mdc-list-item {
  height: auto;
  .mdc-list-item__secondary-text::before {
    height: auto;
  }
}

div.mat-mdc-select-panel {
  min-width: 10rem;
}

.mat-mdc-select-panel .mat-mdc-option {
  min-height: 28px;
  .mdc-list-item__primary-text {
    white-space: nowrap;
  }
  .mat-pseudo-checkbox {
    margin-left: 6px;
  }
}

.mat-mdc-form-field-infix {
  min-height: 0;
}
