@use '@angular/material' as mat;
@import './variables';

// $config: mat.typography-config();

lib-table {
  border-radius: $borderRadius;
  overflow: hidden;
}
.responsive-table {
  max-width: 100vw;
  height: calc(100% - 0.5rem);
  overflow: auto;
  td {
    overflow: hidden;
  }
}
.mat-mdc-cell {
  font-size: inherit;
}
table .mat-mdc-cell {
  line-height: normal;
  .material-icons {
    width: 2rem;
    height: 20px;
    font-size: 1.5rem;
    line-height: 1.4rem;
  }
  .group td .material-icons {
    font-size: 1.2rem;
    line-height: 1.4rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}
.mat-sort-header-container.no-sort {
  cursor: default;
}
.mat-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  &.numeric,
  &.date,
  &.number {
    font-family: 'ABCDiatype Mono';
  }
}
.mat-mdc-table {
  --mat-standard-button-toggle-height: 18px;
  // .mat-mdc-cell:first-of-type .mat-icon-button.filter {
  //   left: -2rem;
  // }
  .filter.mat-mdc-icon-button.mat-mdc-button-base {
    padding: 8px 0;
  }
  .material-icons {
    &.ok {
      color: var(--success);
    }
    &.error {
      color: var(--warning);
    }
    &.new {
      color: var(--todo);
    }
  }
  .mdc-icon-button {
    height: 1rem;
    width: 2rem;
    font-size: 0.6rem;
    padding: 0;
    &.filter {
      position: absolute;
      left: -1.5rem;
      height: 20px;
      width: 2.1rem;
      line-height: 12px;
      z-index: 2;
      padding: 3px !important;
      transition: background-color 0.1s ease-in-out;
      .mat-icon {
        line-height: inherit;
        padding: 0;
      }
      ~ * {
        transition: background-color 0.1s ease-in-out;
      }
      &:hover {
        border-style: dotted;
        border-color: var(--borderColor);
        border-width: 1px 0 1px 1px;
        border-radius: 6px 0 0 6px;
        background-color: var(--background);
        .mat-icon {
          // transform: translateY(-4px);
        }
        ~ * {
          font-weight: bold;
          border-width: 1px 1px 1px 0;
          border-style: dotted;
          border-color: var(--borderColor);
          border-radius: 0 6px 6px 0;
          line-height: 10px;
          background-color: var(--background);
          transform: translateX(-4px);
          &:not(:has(.material-icons)) {
            padding: 4px;
          }
          .material-icons {
            height: 18px;
          }
        }
      }
    }
  }
  .center,
  .right,
  .number,
  .numeric {
    .filter:hover ~ * {
      transform: none;
      width: 100%;
    }
  }
  .mat-mdc-cell:first-child .filter {
    left: 0;
  }
  thead .mat-mdc-header-cell,
  tfoot .mat-mdc-footer-cell {
    background-color: var(--background);
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.9rem;
    font-weight: 500;
  }
  .mat-mdc-header-row {
    vertical-align: bottom;
    .mat-mdc-header-cell > * {
      padding-bottom: 1rem;
    }
  }
  &.inversed {
    tbody {
      background-color: var(--panelBackLight);
      border-radius: $borderRadius;
      .collapse-bar {
        --background: var(--panelBackLight);
      }
      // border-collapse: collapse;
    }
    .mat-mdc-row .mat-mdc-cell .filter {
      color: var(--grey) !important;
    }
  }
  .mat-mdc-header-cell,
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    border-color: var(--borderColor);
    padding-right: 6px;
    position: relative;
    overflow: visible;
    &:not(:first-child) {
      padding-left: 6px;
    }
    // Alignment
    &.right,
    &.right .table-cell-wrapper,
    &.right .mat-sort-header-container {
      text-align: right;
      padding-right: 10px;
      place-content: flex-end;
    }
    &.center,
    &.center .table-cell-wrapper {
      text-align: center;
      justify-content: center;
    }
    // Number alignment
    &.number,
    &.number .table-cell-wrapper,
    &.number .mat-sort-header-container,
    &.mat-mdc-footer-cell > div {
      text-align: right;
      place-content: flex-end;
      padding-right: 10px;
      .mat-sort-header-container {
        justify-content: flex-end;
        .mat-sort-header-arrow {
          transform: translateY(-50%) !important;
        }
      }
    }
    &.mat-mdc-footer-cell.sum > div {
      text-align: left;
    }
    &.number .mat-sort-header-container {
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: right !important;
      .mat-sort-header-content {
        text-align: right;
        span {
          text-align: right;
        }
      }
    }
    // Coloring
    &.negative .table-cell-wrapper {
      color: var(--warning);
    }
    &.positive .table-cell-wrapper {
      color: var(--success);
    }
    // Spacing
    &:first-child {
      padding-left: 24px;
    }
  }
  .mat-sort-header .mat-mdc-cell {
    &.center,
    &.right {
      padding-right: 28px;
    }
  }
  .mat-sort-header-content {
    text-align: left;
  }

  &.dense .mat-mdc-row {
    height: $elementHeight;
    .table-cell-wrapper > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .mat-footer-row {
    background: var(--background);
    &.lined .mat-mdc-footer-cell {
      border-top: 1px solid var(--lightGrey);
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      font-weight: 500;
      border-bottom-style: double;
      border-bottom-width: 5px;
    }
    .mat-subheading-1,
    th {
      // @include mat-typography-level-to-styles($config, subheading-1);
      text-align: left;
      margin: 0;
    }
  }
}

.mat-mdc-table .mat-mdc-cell {
  &.number,
  &.number .table-cell-wrapper {
    align-content: center;
  }
}
tr.selected {
  background-color: var(--panelBack);
}
tr.opened {
  background: var(--panelBackDarker) !important;
}

tbody > tr.active:not(.expanded),
tbody > tr:hover:not(.expanded) {
  @media only screen {
    > td {
      background-color: var(--bgGrey);
    }
  }
}
.inversed tbody > tr.active:not(.expanded),
.inversed tbody > tr:hover:not(.expanded) {
  @media only screen {
    > td {
      background-color: var(--tableHover);
    }
  }
}

.mat-mdc-table[matsort] td {
  padding-right: 1.2rem;
}

.mat-mdc-table tr.mat-mdc-row {
  @media print {
    height: $elementHeight;
    td {
      border-bottom-style: dotted;
    }
  }
}

$selectedBorder: false; // Turn me on to see the selected border
table {
  --selected-border: var(--panelBack);
  width: 100%;
  border-radius: $borderRadius;
  .main-row.expanded td {
    border-bottom: 0;
    background: var(--panelBackDarker);
    @if $selectedBorder {
      border-style: solid;
      border-color: var(--selected-border);
      border-width: 2px 0 0 0;
    }
    .table-cell-wrapper {
      margin-left: -6px;
      padding-left: 6px;
      height: 2.1rem;
    }
    &.number + *:not(.number) .table-cell-wrapper {
      margin-left: -10px;
      padding-left: 10px;
    }
    &:first-child {
      @if $selectedBorder {
        border-left-width: 2px;
      }
    }
    &:last-child {
      @if $selectedBorder {
        border-right-width: 2px;
      }
    }
  }
  tr:not(.detail-row) .mat-mdc-cell .mat-mdc-button-base {
    &.mat-mdc-outlined-button,
    &.mat-mdc-stroked-button,
    &.mat-mdc-raised-button {
      height: 26px;
    }
  }

  .detail-row {
    &:not(.expanded) {
      > * {
        display: none;
      }
    }
    .detail-row > td {
      .group {
        margin: 4px -10px 4px -10px;
      }
      > footer {
        margin: 0 -10px;
      }
    }
    > td {
      @if $selectedBorder {
        border-style: solid;
        border-color: var(--selected-border);
        border-width: 0 2px 2px 2px;
      }
      // background: linear-gradient(180deg, var(--panelBackDarker), var(--panelBack) 100%);
      background: var(--panelBackDarker);
      border-radius: 0 0 7px 7px;
      > footer {
        margin: 0 -4px 0 calc(-2rem + 3px);
        @if $selectedBorder {
          margin: 0 -2rem;
        }
      }
      .group {
        background: var(--background);
        border-radius: $borderRadius;
        padding: 4px 6px;
        margin: 4px 0 4px -10px;
        &.selected {
          outline: 2px solid var(--primary);
        }
        &:hover {
          box-shadow: 0 0 4px var(--primary);
        }

        // Inner table
        table {
          border-collapse: collapse;
          min-width: auto;
          th,
          td {
            padding-right: 0;
            > span {
              display: block;
            }
          }
          th,
          .mat-mdc-cell {
            font-weight: normal;
            // text-align: left;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            &:first-of-type {
              padding-left: 10px;
            }
          }
          td {
            &:first-of-type {
              padding-left: 15px;
            }
            &:last-of-type {
              padding-right: 15px;
            }
          }

          tbody {
            td.mat-mdc-cell {
              border-bottom-width: 0;
            }
            tr:nth-child(2n + 1):not(.selected) {
              background: var(--background);
            }
          }
          tfoot {
            td {
              border-top-color: var(--borderColor);
              border-top-width: 1px;
              border-top-style: dotted;
            }
          }
        }
      }
    }
  }
}
