@use 'sass:math';

.list-view,
.form-view,
.mat-mdc-dialog-surface,
.cdk-overlay-container {
  // Prevent hint placeholders from bleeding out underneath input fields
  .mat-mdc-form-field.no-hint {
    --mat-mdc-form-field-floating-label-scale: 1;
    --mdc-typography-body1-font-size: 1rem;
    --mdc-typography-body1-line-height: 1.1rem;
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }
    .mdc-text-field {
      padding: 0;
    }
    .mat-mdc-form-field-infix {
      width: auto;
      min-height: 0;
      padding: 1px 0;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  .mat-mdc-form-field {
    display: flex;
  }

  // Make input fields denser on desktop
  // @media screen and (min-width: 1080px) {
  $fieldPadding: 0.3em; // 0.75em // default padding
  $labelSpacing: 0; // 0.25em // default spacing between label and input
  $innerPadding: 0.3rem; // 0.6rem default padding;
  .mat-mdc-form-field.mat-form-field-appearance-fill {
    .mat-mdc-form-field-flex {
      margin: 0.2rem 0;
      .mat-mdc-form-field-infix {
        padding: 0; //$labelSpacing 0 $fieldPadding 0;
      }
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  // // Make an inverse input field
  .mat-mdc-form-field.inversed .mat-mdc-form-field-flex {
    background-color: var(--tableBack);
  }

  // // Make a denser input field
  .mat-mdc-form-field.dense .mat-mdc-form-field-flex {
    padding: 0.3rem;
    .mat-mdc-form-field-prefix {
      place-self: flex-end;
      margin: 0 0.3rem;
    }
    .mat-mdc-form-field-infix {
      border-top: 0;
      padding: 0;
    }
  }

  button[type='submit'].mat-button-base .mat-button-wrapper {
    display: flex;
    lib-loader {
      padding: 4px;
      margin-right: 0.5rem;
      margin-left: -1.2rem;
      height: 3rem;
      max-width: 4rem;
      line-height: 3rem;
      display: inline-flex;
      place-items: center;
    }
  }

  // Labels to the left, fields to the right
  // This actually negates the entire reason for material-design, but
  // it is the component library we have chosen to use.
  [role='listbox'] .mat-option {
    font-size: inherit;
    line-height: 3em;
    height: 3em;
  }
  form {
    .row {
      grid-column: 1 / -1;
    }
    .mat-mdc-table {
      .mat-mdc-cell:has(.mat-mdc-input-element):not(:last-child) {
        padding-right: 0;
      }
      .mat-mdc-input-element {
        min-height: 2.2rem;
        // padding: 0 0.4rem;
        // width: auto;
        &.ng-dirty {
          background-color: var(--highlight);
        }
        &.ng-invalid {
          background-color: var(--lightWarning) !important;
        }
      }
    }
    .mat-mdc-input-element,
    .mat-mdc-select {
      border: 1px solid var(--bgGrey);
    }
    .mat-mdc-form-field {
      width: 100%;
    }
    .mdc-text-field--filled {
      background-color: transparent;
    }
    .mat-mdc-form-field {
      &.ng-dirty,
      &.dirty {
        .mdc-text-field .mat-mdc-form-field-flex {
          .mat-mdc-input-element,
          .mat-chip-list-wrapper,
          .mat-mdc-select,
          .mat-mdc-form-field-suffix {
            background-color: var(--highlight);
          }
        }
      }
      &.ng-invalid,
      &.invalid {
        .mat-mdc-input-element,
        .mat-chip-list-wrapper,
        .mat-mdc-form-field-suffix,
        .mat-mdc-select {
          background-color: var(--lightWarning) !important;
        }
      }
      &.mat-mdc-form-field-appearance-fill {
        .mat-mdc-form-field-wrapper,
        .mdc-text-field {
          padding: 0;
          margin: 0;
        }
        .mat-mdc-form-field-flex {
          background-color: transparent;
          padding: 0;
        }
      }
      &.no-label .mat-mdc-form-field-infix {
        grid-template-columns: 1fr;
        .mat-mdc-select,
        .mat-chip-list-wrapper,
        .mat-mdc-input-element {
          grid-column: 1 / -1;
        }
      }
      // &.mat-mdc-form-field-disabled {
      //   .mat-mdc-form-field-infix {
      //     .mat-mdc-input-element,
      //     .mat-mdc-select {
      //       background-color: hsl(from var(--background) h s 90%);
      //     }
      //   }
      // }
      .mat-mdc-form-field-infix {
        display: grid;
        grid-template-columns: 180px minmax(0, 1fr);
        border-top: 0;
        padding: 0;
        place-items: flex-start;
        // Label style
        .mat-mdc-form-field-label-wrapper {
          place-self: center;
        }
        .mdc-floating-label {
          position: static;
          pointer-events: auto;
          padding: 0;
          grid-column: 1;
          grid-row: 1;
          height: auto;
          overflow: visible;
          align-self: center;
          transform: none;
          .mat-mdc-form-field-label,
          mat-label {
            transform: none;
            width: auto;
            margin-top: 0;
            padding: 0;
            position: static;
            white-space: pre-wrap;
            line-height: 1rem;
            overflow: visible;
            display: inline-block;
          }
        }
        // Field style
        .mat-mdc-input-element,
        .mat-mdc-select {
          grid-column: 2;
          grid-row: 1;
          background-color: var(--background);
          border-radius: math.div($borderRadius, 2) math.div($borderRadius, 2) 0 0;
        }
        .mat-mdc-input-element {
          margin: 0;
          padding: $innerPadding 0.25rem;
        }
        .mat-datepicker-input {
          padding: $innerPadding 0.15rem $innerPadding $innerPadding;
        }
        .mat-mdc-select {
          width: calc(100% + 0.5rem);
          .mat-mdc-select-trigger {
            padding: $innerPadding;
            line-height: 1.3rem;
          }
          .mat-mdc-select-arrow-wrapper {
            transform: translateX(-0.25rem);
            height: auto;
          }
        }
      }
      .mat-mdc-form-field-icon-suffix {
        right: 0;
      }
      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        position: absolute;
      }
      .mat-mdc-form-field-prefix,
      .mat-mdc-form-field-suffix {
        background: white;
        place-self: normal;
        transform: translateX(0.5rem);
        margin: 1px 0 1px 0;
        min-width: 2rem;
        > * {
          display: flex;
        }
        > span {
          padding: 0.3rem;
          display: block;
          height: 100%;
        }
      }
      .mat-mdc-form-field-underline,
      .mdc-line-ripple {
        display: none;
      }
    }
  }
  .mat-checkbox-layout {
    position: relative;
    z-index: 2;
  }
}

.mat-mdc-form-field .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  transform: none;
  padding: 0 0.35rem;
}
.mat-mdc-button-base.mat-mdc-icon-button {
  --mdc-icon-button-disabled-icon-color: var(--muted);
  .mat-mdc-button-touch-target {
    height: 100%;
    width: 100%;
  }
}

.mat-mdc-form-field {
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
}
.mat-mdc-form-field {
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    --icon-layer-size: 20px;
    --icon-size: 18px;
    .mat-mdc-icon-button {
      --mdc-icon-button-state-layer-size: var(--icon-layer-size);
      --mdc-icon-button-icon-size: var(--icon-size);
      padding: 0;
    }
    .mat-icon {
      padding: 0;
      width: var(--icon-size);
      height: var(--icon-size);
      font-size: var(--icon-size);
    }
  }
}
.mat-mdc-checkbox {
  margin-left: -4px;
}
